import React from "react"
import Img from "gatsby-image"
// import styled from "styled-components"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

// layout stuff
import Layout from "../components/layout"

// images

import VideoPoster from "../images/movieStillImage.jpg"
import VideoOne from "../images/outerlmitsVideoOne.mp4"
import Screenprinting from "../components/screenprinting"
import SEO from "../components/seo"
import Skateboards from "../components/skateboards"
import PromoProducts from "../components/promoproducts"
import Row from "../components/row"

// backgrounds

import LightBackground from "../images/backgrounds/lightTextureBackground.svg"
import DarkBackground from "../images/backgrounds/darkTextureBackground.svg"

const HeroImgWrapper = styled.div`
  width: 100%;

  @media (min-width: 798px) {
    display: none;
  }
`

const HeroVideoWrapper = styled.div`
  display: none;

    @media (min-width: 798px) {
      display: block;
      width: 100%;
      height: 500px;
      object-fit: contain;
      object-position: center;
      overflow: hidden;
    }
`


const Three = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  gap: 2em;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  `

const BoxA = styled.article`
  text-align: center;
  width: 100%;
  `

const BoxB = styled.article`
  text-align: center;
  width: 100%;
  `
const BoxC = styled.article`
  text-align: center;
  width: 100%;
  `

const About = styled.section`
  display: grid;
  place-items: center;
  min-height: 400px;
  background : url(${DarkBackground});
  color: white;

  box-sizing: content-box;
  padding: 2em 1em;
  border-bottom: 16px solid rgb(195, 22, 28);
  `

const Pricing = styled.section`
  display: grid;
  place-items: center;
  min-height: 400px;
  background : url(${LightBackground});
  // color: white;

  box-sizing: content-box;
  padding: 2em 1em;
  border-bottom: 16px solid rgb(195, 22, 28);
  `

const IndexPage = ( {data } ) => (
  <Layout>
    <SEO title="Outer Limits" />
    {/* schema with const at bottom of the page */}
    <script type="application/ld+json">{JSONwebsite}</script>

    {/* home hero img and video */}
      <div css={css` width: 100%; border-bottom: 20px solid rgb(195, 22, 28);`}>
    {/* image */}
      <HeroImgWrapper>
        <Img fluid={data.heroImage.childImageSharp.fluid} width="100%" height="auto" />
      </HeroImgWrapper>
    {/* video */}
      <HeroVideoWrapper>
        <video src={VideoOne} playsInline autoPlay muted loop poster={VideoPoster} width="100%" />
      </HeroVideoWrapper>
    </div>

    {/* h1 custom clothing */}
    <Row>
    <h1 className="textCenter">CUSTOM CLOTHING &amp;<br />SCREEN PRINTING</h1>
    </Row>

    {/* screen printing promo products skateboards */}
    <Row>
    <Three>
      <BoxA>
        <h2 css={css`text-align: center; text-transform: uppercase;`}>Screen Printing</h2>
        <Screenprinting />
      </BoxA>
      <BoxB>
        <h2 css={css`text-align: center; text-transform: uppercase;`}>Promo Products</h2>
        <PromoProducts />
      </BoxB>
      <BoxC>
        <h2 css={css`text-align: center; text-transform: uppercase;`}>Skateboards</h2>
        <Skateboards />
      </BoxC>
    </Three>
    </Row>

    {/* about us */}
    <About>
      <Row>
      <h2 css={css`text-align: center; text-transform: uppercase;`}>ABOUT</h2>
      <p>The Outer Limits story goes back to April 1st, 1990 we opened up as MR Spin Art Creations doing hand-painted T-shirts and fleece. Offering our customers an interactive experience. Screen printing was another service we offered. As our business grew, more into heat applied transfers, lettering numbers, branded clothing, and the Skate Board shop A few years in, we felt our business had evolved to the point where we had a new mission. So we changed our name to what we felt would have longevity.</p>
      <p>Fast forward 30 years later Outer Limits Clothing Company mainly is focussed on Screen Printing, Embroidery, heat applied letters, numbers on garments, jerseys, and hats along with promotional items such as coffee mugs, pens, drink cozies and stickers. We specialize in small quantities as well as large orders. Our motto is “If you don’t order one, we can’t make you one.”</p>
      </Row>
    </About>

    {/* services products */}
    <section css={css`

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 64px auto;

    width: 100%;

    li {
      margin-top: 4px;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    `}>
    {/* wrapper div */}
    <div css={css` 
    
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;

      gap: 10em;
      justify-content: space-between;
    }
    `}>
        {/* services div */}
        <div>
          <h2>
            SERVICES
          </h2>
          <ul>
            <li>Screen Printing</li>
            <li>Embroidery</li>
            <li>Heat Transfers</li>
            <li>Promotional Products</li>
            <li>Custom Decals</li>
          </ul>
        </div>


        {/* products div */}
        <div>
          <h2>
            PRODUCTS
          </h2>
          <ul>
            <li>Skateboards</li>
            <li>Skateboard Trucks</li>
            <li>Skateboard Wheels</li>
            <li>Skateboard Bearings</li>
            <li>Hats &amp; Clothing</li>
          </ul>
        </div>
      </div>
    </section>

    {/* pricing */}
    <Pricing>
      <Row>
      <h2 css={css`text-align: center; text-transform: uppercase;`}>PRICING</h2>
      <p>Since all of our orders are subject to variables (such as setups, product style, quantities, artwork &amp; design), all of our jobs are quoted individually. If you would like a free quote please give us a call or drop into our location.</p>
      </Row>
    </Pricing>

  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    heroImage: file(relativePath: {eq: "movieStillImage.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
	  }
  }
`

let JSONwebsite = `
{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Outer Limits Clothing and Printing",
  "image": "https://outerlimitsclothing.ca/outerLimitsLogo.jpg",
  "@id": "",
  "url": "https://outerlimitsclothing.ca/",
  "telephone": "+16044671166",
  "priceRange": "$$$",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Unit 100 - 22716 Dewdney Trunk Rd",
    "addressLocality": "Maple Ridge",
    "addressRegion": "BC",
    "postalCode": "V2X3K2",
    "addressCountry": "CA"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 49.220390,
    "longitude": -122.592660
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "9:30",
    "closes": "17:30"
  },
  "sameAs": "https://www.facebook.com/OuterLimitsClothing" 
}

`